import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/layout";
import "./index.scss";
import Pattern from "../images/svg/panel_long_1.svg";
import { graphql } from "gatsby";
import { ChapterTitle } from "../components/Sections/ChapterTitle/chapterTitle"
import { getResource } from "../utils/getResource"
import { SubsectionTitle } from "../components/Sections/SubsectionTitle/subsectionTitle"
import { Section3 } from "../components/Sections/Section3/section3"
import { Section1 } from "../components/Sections/Section1/section1"
import { Section2 } from "../components/Sections/Section2/section2"
import { Section5 } from "../components/Sections/Section5/section5"
import { Section4 } from "../components/Sections/Section4/section4"
import { PageNavigation } from "../components/PageNavigation/pageNavigation"
import { Section6 } from "../components/Sections/Section6/section6"
import { Section3b } from "../components/Sections/Section3/section3b"

const Culture = ({ data, location }) => {
  const pageResources = data.allMarkdownRemark.edges;
  const cultureRefs = useRef([])
  const [pageRefs, setPageRefs] = useState([])

  useEffect(() => {
    const pageRefsArr = cultureRefs.current.map(ref => ({ id: ref.id, offset: ref.offsetTop, height: ref.offsetHeight }))

    setPageRefs(pageRefsArr)
  }, [])

  return (
    <Layout location={location} pageRefs={pageRefs} sectionClass="m-main__content-culture">
      <div className="m-main__content">
        <div className="m-main__section-pattern">
          <Pattern />
        </div>
        <ChapterTitle text={getResource(pageResources, 'kultura-title').content} bgImage={getResource(pageResources, 'kultura-title').bgImage} />
        <div ref={el => cultureRefs.current[0] = el} id="exhibition-culture-houses">
        <SubsectionTitle text={getResource(pageResources, 'kultura-subtitle-1').content} />
          <Section4 bgImgSrc={getResource(pageResources, 'kultura-text-53').bgImage}
                    imgSrc={getResource(pageResources, 'kultura-text-53').image}
                    text={getResource(pageResources, 'kultura-text-53').content}
                    caption={getResource(pageResources, 'kultura-text-53').imageContent}
                    reverse extended
          />
          <Section1 imgCaption={getResource(pageResources, 'kultura-text-54').imageContent} text={getResource(pageResources, 'kultura-text-54').content} imgSrc={getResource(pageResources, 'kultura-text-54').image} isReversed centered />
          <Section5 customSectionClass="m-section__culture-parallax-54" imgSrc={getResource(pageResources, 'kultura-text-img-1').image} caption={getResource(pageResources, 'kultura-text-img-1').imageContent} />
        </div>
        <div ref={el => cultureRefs.current[1] = el} id="exhibition-culture-furniture">
          <SubsectionTitle text={getResource(pageResources, 'kultura-subtitle-2').content} />
          <Section1 imgCaption={getResource(pageResources, 'kultura-text-55').imageContent} text={getResource(pageResources, 'kultura-text-55').content} imgSrc={getResource(pageResources, 'kultura-text-55').image} isReversed centered />
          <Section5 customSectionClass="m-section__culture-parallax-55" imgSrc={getResource(pageResources, 'kultura-img-1').image} caption={getResource(pageResources, 'kultura-img-1').imageContent} />
          <Section1 customClass={'m-section__culture-56'} single text={getResource(pageResources, 'kultura-text-56').content} centered />
          <Section2 option={'imgOnly'}
                    bgImg={getResource(pageResources, 'kultura-img-2').bgImage}
                    caption={getResource(pageResources, 'kultura-img-2').imageContent}
                    imgSrc={getResource(pageResources, 'kultura-img-2').image}
                    customClass={'m-section__culture-img-56'}
          />
          <Section1 text={getResource(pageResources, 'kultura-text-57').content} imgCaption={getResource(pageResources, 'kultura-text-57').imageContent} imgSrc={getResource(pageResources, 'kultura-text-57').image} isReversed centered />
          <Section4 imgSrc={getResource(pageResources, 'kultura-text-58').image}
                    text={getResource(pageResources, 'kultura-text-58').content}
                    caption={getResource(pageResources, 'kultura-text-58').imageContent}
                    bgImgSrc={getResource(pageResources, 'kultura-text-58').bgImage}
                    extended
          />
        </div>
        <div ref={el => cultureRefs.current[2] = el} id="exhibition-culture-churches" className="m-section__culture-churches">
          <SubsectionTitle text={getResource(pageResources, 'kultura-subtitle-3').content} />
          <Section2 option={'twoColumns'}
                    bgImg={getResource(pageResources, 'kultura-text-59').bgImage}
                    caption={getResource(pageResources, 'kultura-text-59').imageContent}
                    imgSrc={getResource(pageResources, 'kultura-text-59').image}
                    text={getResource(pageResources, 'kultura-text-59').content}
                    isReversed
                    imgFull
          />
          <Section3
            imgLeftSrc={getResource(pageResources, 'kultura-text-61').image}
            imgRightSrc={getResource(pageResources, 'kultura-text-60').image}
            txtLeft={getResource(pageResources, 'kultura-text-61').content}
            txtRight={getResource(pageResources, 'kultura-text-60').content}
            imgLeftCaption={getResource(pageResources, 'kultura-text-61').imageContent}
            imgRightCaption={getResource(pageResources, 'kultura-text-60').imageContent}
            imgFull
            imgRightFull
          />
          <Section6 imgSrc={getResource(pageResources, 'kultura-img-3').image} />
          <Section5 customSectionClass="m-section__culture-parallax-62" imgSrc={getResource(pageResources, 'kultura-img-4').image} caption={getResource(pageResources, 'kultura-img-4').imageContent} />
          <Section6 text={getResource(pageResources, 'kultura-text-62').content} />
          <Section3b imgLeftSrc={getResource(pageResources, 'kultura-img-5').image}
                     imgRightSrc={getResource(pageResources, 'kultura-img-6').image}
                     imgLeftCaption={getResource(pageResources, 'kultura-img-5').imageContent}
                     imgRightCaption={getResource(pageResources, 'kultura-img-6').imageContent}
                     customSectionClass="m-section__culture-img-only"
          />
          <Section2 option={'imgOnly'}
                    bgImg={getResource(pageResources, 'kultura-img-7').bgImage}
                    caption={getResource(pageResources, 'kultura-img-7').imageContent}
                    imgSrc={getResource(pageResources, 'kultura-img-7').image}
          />
          <Section6 text={getResource(pageResources, 'kultura-text-63').content} />
          <Section3b imgLeftSrc={getResource(pageResources, 'kultura-img-8').image}
                     imgRightSrc={getResource(pageResources, 'kultura-img-9').image}
                     imgLeftCaption={getResource(pageResources, 'kultura-img-8').imageContent}
                     imgRightCaption={getResource(pageResources, 'kultura-img-9').imageContent}
                     customSectionClass="m-section__culture-img-only"
          />
          <Section2 option={'movie'}
                    bgImg={getResource(pageResources, 'kultura-mov-1').bgImage}
                    movieSrc={getResource(pageResources, 'kultura-mov-1').content}
          />
        </div>
        <PageNavigation prevItem={'exhibition-values'} nextItem={'exhibition-remembrance'} />
      </div>
    </Layout>
  )
}

export default Culture

export const pageQuery = graphql`
query($language: String) {
  allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "culture-item"}, language: { eq: $language }}}
  ) {
    edges {
      node {
        frontmatter {
          title
          language
          order
          content
          image
          imageContent
          bgImage
        }
      }
    }
  }
}`
